<template>
    <div v-if="loading == false">
        <ChirpList
            :data_source="rows"
            :export_api="export_api"
            :totalCount="totalCount"
            :target_page="targetPage"
            @applyCriteria="modifyCriteria"
            :loadingColumns="loadingTable"
            :column_meta="columnMeta"
            :createButton="true"
            :storeKey="storeKey"
            :useOverride="useOverride"
            @cancelOverride="handleCancelOverride"
        />
    </div>
    <div v-else>Loading...</div>
</template>

<script>
    import { tryGetFilter } from '@/util/tryGetFilter';

    const api_root = 'email_templates';

    import ChirpList from '@/components/general/list/ChirpList';

    export default {
        name: 'EmailTemplates',
        components: { ChirpList },
        props: {
            overrideFilter: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                rows: [],
                totalCount: 0,
                targetPage: 'EmailTemplateDetail',
                loading: true,
                loadingTable: false,
                useOverride: false,
                criteriaProps: {},
                export_api: '/email_templates',
                columnMeta: [
                    { field: 'email_template_title', headerText: 'Email Template Title' },
                    { field: 'html', headerText: 'Html' },
                    { field: 'level', headerText: 'Level' },
                ],
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'email-templates';
            },
        },
        methods: {
            async load_data() {
                try {
                    const body = {
                        criteria: this.criteria
                    };
                    const res = await this.$api.post(this.export_api, body);

                    if (res.status >= 400) {
                        this.$toasted.error('Failed to fetch templates roster.');
                        return;
                    }

                    this.rows = res.data.rows;
                    this.criteriaProps = {
                        filters: this.pageMeta.filters,
                    };

                    this.totalCount = res.data.total_count;
                } catch (err) {
                    this.$toasted.error('Failed to fetch templates roster.');
                }
                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter,
                    });
                }
                await this.load_data();
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria,
                    });
                }
                this.criteriaProps = criteria;
                this.load_data();
            },
            handleCancelOverride() {
                this.useOverride = false;
            },
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                },
            },
        },
        async created() {
            await this.init();
        },
    };
</script>
